import { Container } from "@mui/material";
import { FooterBar, Home, LeftSidePanel, MainAppBar, RightSidePanel, Login } from "./Components/SingleUse";
import "./App.css";
import { Route, Routes } from "react-router-dom";

function App() {
  return (
    <Container maxWidth="xl">
      <MainAppBar />
      <div
        style={{
          gridTemplate: '"LeftSidePanel Routes RightSidePanel" 1fr / min-content 1fr min-content',
          marginTop: 48,
          paddingTop: 16,
          alignItems: "center",
          display: "grid",
          flexGrow: 1,
        }}
      >
        <LeftSidePanel />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="Login" element={<Login />} />
        </Routes>
        <RightSidePanel />
      </div>
      <FooterBar />
    </Container>
  );
}

export default App;
