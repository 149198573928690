import { AppBar, Button, IconButton, Toolbar, Typography } from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";

export default function MainAppBar() {
  return (
    <AppBar position="fixed">
      <Toolbar variant="dense">
        <IconButton size="large" edge="start" sx={{ mr: 2 }} color="inherit">
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          ATTF - Pixel Art Creator
        </Typography>
        <Button color="inherit">Login</Button>
      </Toolbar>
    </AppBar>
  );
}
